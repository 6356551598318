import React, { useState, useContext } from "react";
import crypto from "crypto";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import nameLogo from "../../images/AccessApplication/name.png";
import companyLogo from "../../images/AccessApplication/company.png";
import phoneLogo from "../../images/AccessApplication/phone.png";
import CloseButton from "../../images/InfoBlock/clear.png";
import emailLogo from "../../images/AccessApplication/email.png";
import errorLogo from "../../images/AccessApplication/error.png";
import checkedLogo from "../../images/AccessApplication/checked.png";
import validator from "validator";
import NumberFormat from "react-number-format";
import { useFormik } from "formik";
import { handleError, handleResponse } from "../../api/apiUtils";
import Modal from "../ModalComponent/ModalComponent";
import ThemeContext from "../../contexts/ThemeContext";
import "./styles.scss";

const AccessApplication = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { site, title, subtitle, onHide} = props;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const context = useContext(ThemeContext);

  const validate = (values) => {
    const errors = {};
    const phoneReg = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;

    if (!values.name) errors.name = `${t("AccessApplication.5")}`;
    if (validator.isEmpty(values.name, { ignore_whitespace: true }))
      errors.name = `${t("AccessApplication.5")}`;
    if (validator.isInt(values.name))
      errors.name = `${t("AccessApplication.5")}`;
    if (!validator.isLength(values.name, { min: 2, max: 25 }))
      errors.name = `${t("AccessApplication.5")}`;

    if (validator.isEmpty(values.companyName, { ignore_whitespace: true }))
      errors.companyName = `${t("AccessApplication.5")}`;
    if (!values.companyName) errors.companyName = "company must be not blank";
    if (validator.isInt(values.companyName))
      errors.companyName = "company must be not blank";
    if (!validator.isLength(values.companyName, { min: 2, max: 25 }))
      errors.companyName = "name must be not blank";

    if (!phoneReg.test(values.phoneNumber))
      errors.phoneNumber = "name must be not blank";

    if (!validator.isEmail(values.email))
      errors.email = "name must be not blank";

    return errors;
  };

  const sendMessage = (values, url) => {

    const formValues = JSON.parse(JSON.stringify(values));
    formValues.name = values.name.trim();
    formValues.companyName = values.companyName.trim();
    formValues.site = site ? site : values.site;
    const d = new Date();
    const hash = crypto
      .createHash("md5")
      .update(d.toISOString().split("T")[0])
      .digest("hex");
    return fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-Authorization": hash,
      },
      body: JSON.stringify(formValues),
    })
      .then(handleResponse)
      .catch(handleError);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      phoneNumber: "",
      email: "",
      site: "utilitium",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const API_URL = `${process.env.GATSBY_APP_API_URL || ''}/api/form`;
      sendMessage(values, API_URL);
      handleShow();
      resetForm({ values: "" });
    },
  });

  return (
    <>
      <Row lg={12} xl={12} md={12} sm={12} xs={12}>
        <Col
          className="access-application"
          g={12}
          xl={12}
          md={12}
          sm={12}
          xs={12}
        >
          <div className="access-application-title-wrapper">
            <button className="access-application-close btn btn-close" onClick={onHide}>
            <img
                  className="check-image"
                  src={CloseButton}
                  alt="checkedLogo"
                />
            </button>

            <h2 className="access-application-title">
              {title !== "" ? title : t("AccessApplication.1")}
            </h2>
            <span className="access-application-subtitle">
              {subtitle ? subtitle : t("AccessApplication.2")}
            </span>
          </div>
          <form
            className="access-application-form"
            onSubmit={formik.handleSubmit}
          >
            <div className="input-wrapper">
              <img className="input-logo" src={nameLogo} alt="nameLogo" />
              <input
                className={`input ${!formik.errors.name && formik.touched.name
                    ? "checked"
                    : formik.errors.name && formik.touched.name
                      ? "error"
                      : ""
                  }`}
                type="text"
                type="text"
                name="name"
                maxLength="50"
                value={formik.values.name}
                placeholder={t("AccessApplication.3")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.name && formik.touched.name && (
                <span className="input-error">{t("AccessApplication.5")}</span>
              )}
              {formik.errors.name && formik.touched.name && (
                <img className="error-image" src={errorLogo} alt="errorLogo" />
              )}
              {!formik.errors.name && formik.touched.name && (
                <img
                  className="check-image"
                  src={checkedLogo}
                  alt="checkedLogo"
                />
              )}
            </div>
            <div className="input-wrapper" ref={context.formRef}>
              <img className="input-logo" src={companyLogo} alt="companyLogo" />
              <input
                className={`input ${!formik.errors.companyName && formik.touched.companyName
                    ? "checked"
                    : formik.errors.companyName && formik.touched.companyName
                      ? "error"
                      : ""
                  }`}
                type="text"
                name="companyName"
                maxLength="50"
                value={formik.values.companyName}
                placeholder={t("AccessApplication.4")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.companyName && formik.touched.companyName && (
                <span className="input-error">{t("AccessApplication.6")}</span>
              )}
              {formik.errors.companyName && formik.touched.companyName && (
                <img className="error-image" src={errorLogo} alt="errorLogo" />
              )}
              {!formik.errors.companyName && formik.touched.companyName && (
                <img
                  className="check-image"
                  src={checkedLogo}
                  alt="checkedLogo"
                />
              )}
            </div>
            <div className="input-wrapper">
              <img className="input-logo" src={phoneLogo} alt="phoneLogo" />
              <NumberFormat
                className={`phone-number-input input ${!formik.errors.phoneNumber && formik.touched.phoneNumber
                    ? "checked"
                    : formik.errors.phoneNumber && formik.touched.phoneNumber
                      ? "error"
                      : ""
                  }`}
                name="phoneNumber"
                type="text"
                format="+38(0##)#######"
                mask="_"
                allowEmptyFormatting
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <span className="input-error">{t("AccessApplication.7")}</span>
              )}
              {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <img className="error-image" src={errorLogo} alt="errorLogo" />
              )}
              {!formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <img
                  className="check-image"
                  src={checkedLogo}
                  alt="checkedLogo"
                />
              )}
            </div>
            <div className="input-wrapper">
              <img className="input-logo" src={emailLogo} alt="emailLogo" />
              <input
                className={`input ${!formik.errors.email && formik.touched.email
                    ? "checked"
                    : formik.errors.email && formik.touched.email
                      ? "error"
                      : ""
                  }`}
                type="text"
                name="email"
                maxLength="40"
                value={formik.values.email}
                placeholder={t("AccessApplication.8")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email && formik.touched.email && (
                <span className="input-error">{t("AccessApplication.8")}</span>
              )}
              {formik.errors.email && formik.touched.email && (
                <img className="error-image" src={errorLogo} alt="errorLogo" />
              )}
              {!formik.errors.email && formik.touched.email && (
                <img
                  className="check-image"
                  src={checkedLogo}
                  alt="checkedLogo"
                />
              )}
            </div>
            <button
              type="submit"
              className="submit-button"
              onSubmit={formik.handleSubmit}
            >
              {t("AccessApplication.9")}
            </button>
          </form>
        </Col>
        <Modal show={show} handleClose={handleClose} onHide={onHide}/>
      </Row>
    </>
  );
};

export default AccessApplication;
