import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import modalImage from "../../images/ModalComponent/modal.png";
import "./styles.scss";

const ModalComponent = ({ handleClose, show, onHide }) => {
  const { t } = useTranslation();
  const close = () => {
    handleClose();
    onHide();
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="modal-content-wrapper">
            <img className="modal-image" src={modalImage} alt="modalImage" />
            <h2 className="modal-title">{t(`ModalComponent.1`)}</h2>
            <p className="modal-content">{t(`ModalComponent.2`)}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div onClick={() => close()} className="modal-close-button">
            {t(`ModalComponent.3`)}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalComponent;
